import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

// import  ModalItem  from '../components/ModalItem'
import  TopAccordion  from '../components/TopAccordion'
import { Button, Container, Row, Col, Accordion, useAccordionToggle} from 'react-bootstrap'

import CarouselComponent from '../components/Carousel'
import {TopNavbarNavs} from '../components/TopNavbar'

// -------- Post Template -------- //
// const MyCallback = (carouselimagedescription) => {
//   return (
//     console.log(carouselimagedescription) +
//     <p>{carouselimagedescription}</p>
//   )
// }

export const BlogPostTemplate = ({
  tags,
  title,
  subtitle,
  carousel,
  helmet,
}) => {


  
  return (

    <section className="sec-mrg">
      {helmet || ''}
      <Container fluid className="">
        <Row className="text-center">
          <Col xs={{span: 10, offset: 1}}>
            <h4 className="">
              {title}
            </h4>
            <h5 className="mb-5">
              {subtitle}
            </h5>
          </Col>
        </Row>
        <Row>
            <Col xs={{span: 10, offset: 1}}>
              <CarouselComponent carouselData = {carousel}/>
            {/* <h1>{MyCallback()}</h1> */}
            {/* -------- next / prev buttons  -------- */}
            {/* 
            {next && <h1><Link to={next}>the Next Slug:</Link></h1>}
            {prev && <h1><Link to={prev}>the Prev Slug:</Link></h1> } 
            */}
            {/* <PostContent content={content} /> */}
            </Col>
        </Row>      
        <Row>
          <Col xs={{span: 10, offset: 1}}>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                {/* <h4>Tags</h4> */}
                {/* <ul className="taglist"> */}

                <p className='work-item-tags'> See More: 
                  {tags.map((tag, index) => (
                      <React.Fragment>
                      <Link className='text-capitalize' key={tag + `tag`} to={`/tags/${kebabCase(tag)}/`}> {tag}s</Link>
                      {index <= tags.length-2 ? ',' : ''}
                      </React.Fragment>
                  ))}  
                </p>
                {/* </ul> */}
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  country: PropTypes.string,
  year: PropTypes.string,
  commissioner: PropTypes.string,
  colaborators: PropTypes.string,
  subtitle: PropTypes.string,
  shortdescription: PropTypes.string,
  worklinks: PropTypes.array,
  carousel: PropTypes.arrayOf(
    PropTypes.shape({
      carouselimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      carouselimagedescription: PropTypes.string,
    })
  ),
}

// --------- Top Navbar for Blog-Post page ---------//

export const BlogPostNavbar = ({
  description,
  // tags,
  title,
  country,
  year,
  commissioner,
  colaborators,
  subtitle,
  shortdescription,
  worklinks
}) => {

    return (
      <Container fluid className=''>
        <Accordion  className='fixed-top '>
          <TopAccordion navbarBackroundColor='green'/>
            <nav className='nav-gradient'>
              <Container fluid className=''>
                <Row className='d-flex justify-content-between'>
                    <TopNavbarNavs
                      modalBtnTxt='Details'
                      leftLinkClass='navbar-item' 
                      leftTo='/blog/' 
                      modal='workdetails'
                      leftLinkText='Back'
                      title={title}
                      country={country}
                      year={year}
                      commissioner={commissioner}
                      colaborators={colaborators}
                      description={description}
                      worklinks={worklinks}
                      description={description}
                      subtitle={subtitle}
                      shortdescription={shortdescription}
                    />
                  </Row>
                </Container>
              </nav>
            </Accordion>
          </Container>
        )
      }

      
// -------- Blog Post -------- //

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data
  // const {nextSlug, prevSlug} = pageContext

  return (
    <Layout>
      
      {/* Navbar */}

      <BlogPostNavbar 
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        description={post.frontmatter.description}
        shortdescription={post.frontmatter.shortdescription}
        year={post.frontmatter.year}
        commissioner={post.frontmatter.commissioner}
        colaborators={post.frontmatter.colaborators}
        country={post.frontmatter.country}
        worklinks={post.frontmatter.worklinks}
      // slug={post.fields.slug}
      // tags={post.frontmatter.tags}
      />

      {/* Blog-Post */}
      <BlogPostTemplate
        
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        // next={nextSlug}
        // prev={prevSlug}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
          tags={post.frontmatter.tags}
          title={post.frontmatter.title}
          country={post.frontmatter.country}
          year={post.frontmatter.year}
          commissioner={post.frontmatter.commissioner}
          colaborators={post.frontmatter.colaborators}
          subtitle={post.frontmatter.subtitle}
          shortdescription={post.frontmatter.shortdescription}
          worklinks={post.frontmatter.worklinks}
          carousel={post.frontmatter.carousel}
        />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        country
        commissioner
        colaborators
        shortdescription
        subtitle
        year
        worklinks
        carousel {
          carouselimage {
            relativePath
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100, traceSVG: {
                color: "SpringGreen"
                optTolerance: 0.4
                turdSize: 100
                turnPolicy: TURNPOLICY_MINORITY
              }) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          carouselimagedescription
        }
      }
    }
  }
`



// Accordion

// // Acordion Toggle Button
// const AcordionButton = () => {
//   return(
//     <TagsToggle eventKey="0"><p className='topnav'>Acordion</p></TagsToggle>
//   )
// }
// // Acordion Toggle Open 
// function TagsToggle({ children, eventKey }) {
//   const decoratedOnClick = useAccordionToggle(eventKey)
//   return (
//     <Button
//       type="button"
//       onClick={decoratedOnClick}
//       className='arrow-up-dropdown'
//     > 
//       {children}
//     </Button>
//   );
// }

// // Acordion Toggle Close 
// function TagsClose({ children, eventKey }) {
//   const decoratedOnClick = useAccordionToggle(eventKey)
//   return (
//     <Button
//       type="button"
//       onClick={decoratedOnClick}
//       className='x-dropdown'
//     >
//       {children}
//     </Button>
//   );
// }
