import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Carousel, Col, Row } from 'react-bootstrap';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'




// const SendDescription = (caption) => {
// return (
//     console.log(caption) 
//     // <p>{caption}</p>
// )
// }


const CarouselComponent = ( props ) => {

    let a = props.carouselData
    const [index, setIndex] = useState(0);
    const max = a.length
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        };
              
    return (
            <React.Fragment>
        <Carousel activeIndex={index} onSelect={handleSelect}  indicators={false}>

        { a.map((carouselItem, index) => (
 
        <Carousel.Item key={carouselItem.carouselimage.id} key={carouselItem.carouselimage.relativePath}>
            <PreviewCompatibleImage imageInfo={carouselItem.carouselimage} />
            {/* <Carousel.Caption>
            <div className='carousel-caption'><p>{carouselItem.carouselimagedescription}</p></div>
            </Carousel.Caption> */}
        </Carousel.Item> 

        )) 
    }
        </Carousel> 
        
        <Row className='carousel-captions'> 
           <Col>
            <h5>{(index+1) + '/' + (max)}</h5>
           </Col>
           {/* <Col>
           <ol className='carousel-indicators'>
            {a.map((items,index) => (
                // items.carouselimagedescription ? <p key={`item ${index}`}>{index}</p> : ''
                items.carouselimagedescription ? <li></li> : ''
            ))}
            </ol> */}
            {/* <a className='carousel-control-prev' href="#">sfdfsf</a> */}
           {/* </Col> */}
           <Col className='text-right'>
            <h5>{a[index].carouselimagedescription ? a[index].carouselimagedescription : null}</h5>
           </Col> 
        </Row>

        </React.Fragment>
    )
}
  
export default CarouselComponent


// Carousel.propTypes = {
//     carousel: PropTypes.arrayOf(
//       PropTypes.shape({
//         carouselimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//         carouselimagedescription: PropTypes.string,
//       })
//     ),
//   }
